//color
@green: #00953a;
@glay: #e6e6e6;
@orange: #ff6600;



// 已认领 待认领 已完成 已过期的背景颜色
@bg-green: #e0f7e9;
@tag-green: @green;

@bg-orange: #fdede2;
@tag-orange: @orange;

@bg-blue: #e4f2fe;
@tag-blue: #1b6aad;

@bg-glay: #e6e6e6;
@tag-glay: @light-black;

@transition: .3s;



@white: #ffffff;
@light-green: #00af82;
@brown: #b48246;
@red: #e60012;
@blue: #1b6aad;

@black: #333;
@light-black: #666;
@gray: #999;

@border-color: #ededed;

@bg-color: #f5f5f5;

@page-bg-color: @bg-color;

@input-bg-color: #f1f1f1;

:root{
	--cus-form__active-color: @green;
}
