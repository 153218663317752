@import './var';

.iconGen(@icon,@width:10px,@height:10px) {
  background: url('@{icon_url}@{icon}.png') no-repeat center;
  width: @width;
  height: @height;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}

.icon-checkbox__checked {
  .iconGen('icon-checkbox__checked', 0.36rem, 0.36rem);
}

.icon-checkbox__unchecked {
  .iconGen('icon-checkbox__unchecked', 0.36rem, 0.36rem);
}

.icon-checkbox__disabled {
  .iconGen('icon-checkbox__disabled', 0.36rem, 0.36rem);
}

.icon-radio__checked {
  .iconGen('icon-radio__checked', 0.36rem, 0.36rem);
}

.icon-radio__unchecked {
  .iconGen('icon-radio__unchecked', 0.36rem, 0.36rem);
}

.icon-radio__disabled {
  .iconGen('icon-radio__disabled', 0.36rem, 0.36rem);
}

.icon-people {
  .iconGen('icon-people', 0.41rem, 0.46rem);
}
.icon-close {
  .iconGen('icon-close', 0.66rem, 0.66rem);
}
.icon-close-gray {
  .iconGen('icon-close-gray', 0.66rem, 0.66rem);
}

.icon-submit-success-logo {
  .iconGen('icon-submit-success-logo', 1.42rem, 1.16rem);
}
.icon-quick-volunteer {
  .iconGen('icon-quick-volunteer', 0.36rem, 0.32rem);
}

.icon-more-menu{
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  .circle;
  .circle{
    border-radius: 50%;
    width: 5px;
    height: 5px;
    flex-shrink: 0;
    background-color: @gray;
  }
  &:before, &:after{
    content: '';
    position: absolute;
    .circle;
  }
  &:before{
    left: -8px;
  }
  &:after{
    right: -8px;
  }
}

.icon-triangle-green {
  .iconGen('icon-triangle-green', 1.88rem, 1.22rem);
}

.icon-arrow-down-green {
  .iconGen('icon-arrow-down-green', 1.28rem, .72rem);
}
