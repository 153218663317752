@import './var';
@import './common';

.form-disabled{
    pointer-events: none;
}


.mt20 {
    margin-top: 0.2rem;
}

.popup-wrapper .icon-close {
    left: unset;
    right: 0;
}

input[type="button"], input[type="submit"], input[type="reset"], input[type="search"] {
    -webkit-appearance: none;
}

.flex-percent-bg-white {
    background-color: #fff;
    margin-bottom: 0.3rem;
    flex: 1;
    padding: unset;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

// 提示微信端访问页面
.page-login-tips {
    .flex;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    main {
        width: 100%;
        .flex;
        flex-grow: 1;
        flex-shrink: 0;
        background: url('@{image_url}tip-background.jpg') no-repeat center;
        background-size: cover;
        padding: 1.3rem 0.8rem 0.3rem;
        .logo-img {
            height: 1.4rem;
            text-align: center;
            margin-bottom: 0.6rem;
        }
        .content {
            padding: 0.5rem 0.5rem;
            background-color: #fff;
            border-radius: 4px;
            height: 6.6rem;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            font-size: 14px;
            line-height: 1.5;
            > .img-box {
                margin-bottom: 0.6rem;
            }
            p {
                line-height: 1.5;
            }
        }
    }
}

// 志愿服务者证书
.page-certificate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    main {
        width: 100%;
        padding: 1.46rem 1.04rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: url('@{image_url}certificate-background.png') no-repeat center;
        background-size: cover;
        .top {
            .flex;
            flex-direction: column;
            align-items: center;
            .upper {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 0.73rem;
                .logo {
                    width: 1.65rem;
                    height: 1.03rem;
                    margin-bottom: 0.27rem;
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .paper-name {
                    width: 2.89rem;
                    height: 0.77rem;
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .content {
                margin-bottom: 0.15rem;
                pre {
                    font-size: 0.24rem;
                    line-height: 1.8;
                    color: #000;
                }

            }
        }
        .bottom {
            .flex;
            flex-direction: column;
            align-items: flex-end;
            > div {
                flex-direction: column;
                .flex-center;
                .signature {
                    font-size: 0.22rem;
                    line-height: 1.8;
                    .flex;
                    flex-direction: column;
                    align-items: center;
                }
                .img-box {
                    .flex-center;
                    width: 2.77rem;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    .btn-download-desc {
        margin-top: 0.5rem;
        color: #07c160;
        font-size: 1.07692308em;
    }
}

// 两版首页头部
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 1.08rem;
    background-color: @white;
    .flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        display: block;
        height: 100%;
        box-sizing: border-box;
        padding: 0.29rem 0;
        > img {
            height: 100%;
            width: auto;
        }
    }
    .people {
        display: flex;
        align-items: center;
        margin-right: -0.2rem;
        height: 100%;
        padding: 0 0.2rem;
        color: @green;
        i{
            font-size: .5rem;
        }
        .desc{
            font-size: .26rem;
            font-weight: bold;
            margin-left: .1rem;
        }
    }
}

// 底部
.footer {
    background-color: #f5f5f5;
    padding: 0.68rem 0;
    font-size: 0.24rem;
    .flex-center;
    flex-direction: column;
    color: #808080;
    a {
        font-size: 0.24rem;
        color: #808080;
    }
    .party-b {
        margin-top: 0.14rem;
        .flex;
        justify-content: center;
        .company {
            margin-left: 0.28rem;
        }
    }
}

// 首页的body
.page-index-user {
    // 免费法律服务
    .free-law-serve {
        .font_family {
            font-size: 0.46rem;
        }
        .text {
            margin-left: 0.2rem;
            font-size: 0.3rem;
        }
        .common + .common {
            margin-left: 0.33rem;
        }
    }

    // 普法课程
    .law-course {
        .swiper-container {
            width: 100%;
        }
        .law-course-list {
            &-item {
                width: 2.6rem;
                margin-right: 0.25rem;
                a {
                    width: 100%;
                    .flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .img-box {
                        position: relative;
                        .b-r;
                        overflow: hidden;
                        .img-wrap(100%, 1.83rem);
                        margin-bottom: 0.18rem;
                        .flex-center;
                        i {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 0.42rem;
                            color: @white;
                        }
                        > img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .video-title {
                        font-size: 0.28rem;
                        line-height: 1.5;
                        color: @black;
                    }
                }
            }
        }
    }

    // 新闻动态
    .news {
        .news-list {
            .news-item {
                .img-box {
                    width: 2.1rem;
                    height: 1.47rem;
                    margin-right: 0.25rem;
                    .b-r;
                    overflow: hidden;
                }
                .content {
                    align-self: flex-start;
                    h3 {
                        font-size: 0.28rem;
                        line-height: 1.5;
                        color: @black;
                        margin-bottom: 0.1rem;
                    }
                    .date {
                        font-size: 0.24rem;
                        color: #999;
                    }
                }
                & + .news-item {
                    margin-top: 0.35rem;
                }
            }
        }
    }
}

    // 用户反馈
    .user-evaluate-list {
        .user-evaluate-item {
            padding-bottom:  0.29rem;
            border-bottom: 0.02rem solid #ededed;
            .name_container {
                display: flex;
                align-items: center;
                font-size: 0.28rem;
                .name {
                    color: #333;
                }
                .offer {
                    color: #ff6600;
                }
            }
            .book_name {
                width: 100%;
                height: 0.5rem;
                line-height: 0.5rem;
                background-color: #e0f7e9;
                border-radius: 0.08rem;
                font-size: 0.26rem;
                color: #009e3b;
                margin-top: 0.2rem;
                padding: 0 0.2rem;
            }
            .desc_container {
                font-size: 0.24rem;
                color: #444;
                line-height: 1.6;
                margin: 0.2rem 0 0.25rem 0;
                .nut-ellipsis-text {
                    color: #009e3b;
                    font-size: 0.24rem;
                }
            }
            .imgae_list {
                .number-left-list(0.1rem, 3, 0.1rem);
                .img_container {
                    border-radius: 0.08rem;
                    overflow: hidden;
                    height: 1.67rem;
                    position: relative;
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .pic_more {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(0,0,0,0.6);
                        color: #fff;
                        font-size: 0.3rem;
                        .flex-center;
                    }
                }
            }
            & + .user-evaluate-item {
                margin-top: 0.3rem;
            }
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
// 志愿者的首页body
.page-index-volunteer {
    // 志愿服务
    .volunteer-serve {
        .font_family {
            font-size: 0.44rem;
        }
        .text {
            margin-left: 0.09rem;
            font-size: 0.28rem;
        }
        .common + .common {
            margin-left: 0.2rem;
        }
    }

    // 法律咨询

}

// 法律服务页面
.page-law-serve {
    display: unset;
    h3 {
        margin-top: 0.1rem;
    }
    .content {
        .module {
            margin-top: 0.43rem;
            .small-title {
                font-size: 0.28rem;
                color: @black;
                margin-bottom: 0.2rem;
            }
            .law-serve-btn-box {
                .btn {
                    font-size: 0.32rem;
                    width: 100%;
                    height: 1.08rem;
                    border-radius: 0.1rem;
                    & + .btn {
                        margin-top: 0.5rem;
                    }
                }

            }
        }
        .organ {
            .btn {
                background-color: #f1f1f1;
                color: @light-black;
            }
        }
    }

    // 弹窗页面
    .popup-wrapper {
        border-radius: 0.08rem;
        .flex;
        flex-direction: column;
        .popup-content {
            overflow-y: visible;
            padding: unset;
            &-text {
                margin-top: 0.32rem;
                overflow-y: auto;
                max-height: 5rem;
                .text {
                    margin: 0 0.24rem;
                }
            }

        }
        .bottom {
            .flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.45rem;
            height: 0.8rem;

            .msg {
                opacity: 0;
                transition: all .3s;
                font-size: 0.3rem;
                color: @gray;
                .text-middle;
                line-height: 0.5rem;
            }
            .btn {
                opacity: 0;
                transition: all .3s;
                height: 0.8rem;
                border-radius: 1.08rem;
                width: 3.5rem;
                font-size: .28rem;
            }
            .msg,.btn{
                &.active{
                    opacity: 1;
                }
            }
        }
    }

}


// 成为志愿者头部的进度条
.process {
    padding: 0.45rem 0;
    .circle-list {
        font-size: 0.24rem;
        display: flex;
        justify-content: center;
        .circle-item {
            .flex;
            flex: 1;
            flex-direction: column;
           align-items: center;
            .big-circle {
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                width: 0.48rem;
                height: 0.48rem;
                margin-bottom: 0.15rem;
                .circle-outer {
                    width: 100%;
                    height: 100%;
                    background-color: #b3b3b3;
                    opacity: 0.4;
                }
                .circle {
                    z-index: 2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    width: 0.42rem;
                    height: 0.42rem;
                    line-height: 0.42rem;
                    text-align: center;
                    color: @white;
                    background-color: #b3b3b3;
                }
            }
            &.active {
                .circle-outer {
                    .bg-green-color;
                }
                .circle {
                    .bg-green-color;
                    font-size: 0.24rem;
                    color: @white;
                }

            }
        }

        .line {
            flex-shrink: 0;
            position: relative;
            // flex: 1;
            width: 1.15rem;
            margin: 0 0.1rem;
            top: 0.24rem;
            height: 0.01rem;
            background-color: #dbdbdb;
            &:last-child{
                display: none;
            }
        }
    }
}

// 04-01 观看培训视频
.look-video {
    width: 6.12rem;
    margin: 0 auto;
    padding-top: 0.35rem;
    &-content {
        padding-bottom: 0.25rem;
        video {
            margin-top: 0.3rem;
            width: 100%;
            height: 4.29rem;
        }
        p {
            margin-top: 0.2rem;
        }
    }
}

// 搜索框
.search-box{
    .row-box;
    background-color: #fff;
    .container;
    &.need-add-service-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .service-btn {
            flex-shrink: 0;
            margin-left: 0.1rem;
            a {
                display: block;
                font-size: 0.26rem;
                color: #fff;
                padding: 0.12rem;
                background-color: #00953a;
                border-radius: 0.06rem;
            }
        }
        .search-group {
            flex-grow: 1;
        }
    }
}

.search-group{
    position: relative;
    padding-top: .3rem;
    padding-bottom: .3rem;
    height: 1.4rem;
    input[type=search] {
        background-color: #f2f2f2;
        appearance: none;
        color: @gray;
        line-height: 0.8rem;
        font-size: 0.26rem;
        width: 100%;
        height: 100%;
        border: 0.01rem solid #f2f2f2;
        border-radius: 0.39rem;
        padding-left: 0.25rem;
        padding-right: 1rem;
    }
    .cu-btn {
        padding: 0 0.1rem;
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        right: .2rem;
        top: .3rem;
        height: .8rem;
        .icon {
            font-size: 0.4rem;
            color: @green;
        }
    }
}
.search-frame {
    background-color: @white;
    width: 100%;
    .flex-center;
    padding: 0 0.3rem;
    form {
        flex-grow: 1;
        .form-wrap {
            width: 100%;
            .flex-center;
            position: relative;

        }
    }
    .select-div {
        width: 1.7rem;
        padding: 0.16rem 0.1rem;
        background-color: #f2f2f2;
        border-radius: 0.39rem;
        margin-left: 0.18rem;
        .select-type {
            color: @light-black;
            font-size: 0.26rem;
            background-color: transparent;
        }
    }

}



// 05 普法课程
.page-law-course-detail {
    .filter-tag{
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .course-list {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: -0.41rem;
        // margin-right: -0.32rem;
        // padding-top: 0.4rem;
        .course-item {
            width: 3.14rem;
            height: 3.48rem;
            background-color: #fff;
            .b-r;
            overflow: hidden;
            margin-top: 0.41rem;
            // margin-right: 0.32rem;
            a {
                width: 100%;
                .flex;
                flex-direction: column;
                justify-content: space-between;
                .img-box {
                    position: relative;
                    .b-r;
                    overflow: hidden;
                    margin-bottom: 0.18rem;
                    .flex-center;
                    .img-wrap(100%, 2.2rem);
                    i {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 0.42rem;
                        color: @white;
                    }
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .video-title {
                    padding: 0 0.18rem;
                    font-size: 0.28rem;
                    line-height: 1.5;
                    color: @black;
                }
            }
        }
    }
}

// 06 捐赠支持
.donation-support {
    padding: 0.1rem 0.3rem 0 0.3rem;
    .donation-list {
        .donation-item {
            margin-top: 0.32rem;
            width: 100%;
            height: 5.28rem;
            background-color: #fff;
            .b-r;
            overflow: hidden;
            a {
                .flex;
                flex-direction: column;
                height: 100%;
                .img-box {
                    .img-wrap(100%, 4.2rem);
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .title {
                    flex-grow: 1;
                    .flex;
                    align-items: center;
                    h3 {
                        padding: 0 0.25rem;
                        font-size: 0.3rem;
                        color: @black;
                    }
                }
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
        }
    }
}

.page-law-ask{
    .filter-tag-inner-box{
        padding-top: .4rem;
        padding-bottom: .14rem;
    }
    .law-ask-list {
        border-radius: 0.08rem;
        margin-top: 0.2rem;
        .law-ask-item {
            width: 100%;
            padding-top: 0.26rem;
            a {
                width: 100%;
                padding: 0 .25rem;
                .flex;
                .left {
                    margin-right: 0.23rem;
                    .icon {
                        font-size: 0.37rem;
                    }
                }
                .r {
                    flex-grow: 1;
                    border-bottom: 0.02rem solid #ededed;
                    h3 {
                        margin-bottom: 0.1rem;
                        font-size: 0.28rem;
                        line-height: 1.5;
                        color: @black;
                    }
                    .r-bottom {
                        align-items: center;
                        .flex;
                        font-size: 0.24rem;
                        color: @gray;
                        padding-bottom: 0.2rem;
                        div {
                            margin-left: 0.64rem;
                            &:nth-child(1) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}


// 07-01 详情 07-03已认领 07-05已完成 08-01详情 08-04已认领 08-07已完成 08-08详情2 08-09详情3
.law-ask-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .page {
        padding: unset;
        height: 100%;
    }
    .law-ask-detail-title {
        background-color: #fff;
        padding: 0.65rem 0.3rem 0.36rem 0.3rem;
        .msg {
            margin-top: 0.34rem;
            .people-msg {
                .flex;
                align-items: center;
                margin-bottom: 0.37rem;
                .img-box {
                    border-radius: 50%;
                    overflow: hidden;
                    .img-wrap(0.58rem, 0.58rem);
                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .name, span {
                    font-size: 0.24rem;
                }
                .name {
                    margin-right: 0.14rem;
                    margin-left: 0.14rem;
                    color: @light-black;
                }
                span {
                    color: @gray;
                }

            }
            .tag-msg {
                color: @gray;
                font-size: 0.24rem;
                .flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    .law-ask-detail-content {
        padding: 0 0.3rem 2.3rem 0.3rem;
        margin-top: 0.12rem;
        background-color: #fff;
        flex-grow: 1;
        > pre {
            font-size: 0.26rem;
            line-height: 1.8;
            color: @light-black;
            &.haveborder {
                border-bottom: 0.01rem solid #ededed;
            }
        }
        .accept-lwayer {
            width: 100%;
            .flex;
            padding-top: 0.51rem;
            .img-box {
                margin-right: 0.15rem;
                .img-wrap(0.82rem, 0.82rem);
                border-radius: 50%;
                overflow: hidden;
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
            .layer-comment {
                .name-id {
                    .flex;
                    align-items: center;
                    .name {
                        margin-right: 0.15rem;
                        font-size: 0.28rem;
                        color: @black;
                    }
                    .id {
                        font-size: 0.24rem;
                        color: @white;
                    }
                }
                .company {
                    margin-top: 0.1rem;
                    margin-bottom: 0.2rem;
                    font-size: 0.24rem;
                    color: @gray;
                }
                .comment {
                    line-height: 1.8;
                    font-size: 0.24rem;
                    color: @light-black;
                }
            }
        }
    }

}

// 底部自适应一个或者两个按钮加一个小标签
.fix-bottom{
    position: fixed;
    z-index: 4;
    bottom: 0;
    width: 100%;
    height: @fix-bottom-height;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    transition: all .3s;
    max-width: @max_width;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0.02rem 0.12rem 0
		rgba(0, 0, 0, 0.3);

    &.to-bottom{
      transform: translateY(100%);
    }

    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: -34px;
      right: 0;
      background-color: #fff;
      height: 34px;
    }
    .flex-88{
      flex: 0 0 88%;
    }
    .flex-75{
      flex: 0 0 75%;
    }
}
.fix-bottom-area{
    .icon-area{
      .flex-center;
      padding: 0 .3rem;
      margin-left: -.3rem;

      .icon-wrapper{
        transform: rotateZ(-180deg);
        font-size: 0;
        .font_family {
            font-size: 0.4rem;
        }
      }
    }
    .btn{
        border-radius: 0.44rem;
      flex-grow: 1;
      & + .btn{
        margin-left: .2rem;
      }
    }
}
.btn {
	&.btn-b{
	  height: .88rem;
	  font-size: .32rem;
	}
    &.btn-finish {
        background-color: #e6e6e6;
        color: @light-black;
    }
}

// 09志愿培训
.page-teach-volunteer {
    .filter-tag-inner-box{
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .teach-volunteer-list {
        .list(2.1rem,1.47rem, .25rem);
        // padding-left: .25rem;
        // padding-right: .25rem;
        background-color: #fff;
        .teach-volunteer-item {
            margin-top: 0.35rem;
            &:nth-child(1) {
                margin-top: 0;
            }
            a {
                .content {
                    h3 {
                        font-size: 0.28rem;
                        color: @black;
                    }
                    p, .date {
                        margin-top: 0.15rem;
                        font-size: 0.24rem;
                        color: @gray;
                    }
                }
            }
        }
    }
}

// 09-01详情
.teach-volunteer-detail {
    .top {
        position: relative;
        width: 100%;
        height: 7.66rem;
        background-color: #fff;
        .img-box {
            .img-wrap(100%, 5.04rem);
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .report-msg {
            position: absolute;
            margin: 0 0.3rem;
            // top: 3.84rem;
            bottom: 0.42rem;
            background-color: #fff;
            z-index: 2;
            padding: 0.32rem 0 0.32rem 0.3rem;
            box-shadow: 0rem 0.02rem 0.08rem 0rem
		            rgba(1, 24, 10, 0.1);
	                border-radius: 0.08rem;
            width: calc(100% - 0.3rem - 0.3rem);
            .msg {
                margin-top: 0.25rem;
                .item {
                    .flex;
                    align-items: center;
                    margin-top: 0.1rem;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    .font_family {
                        color: @orange;
                        font-size: 0.3rem;
                        margin-right: .1rem;
                    }
                    p {
                        color: @light-black;
                        font-size: 0.24rem;
                    }
                }
            }
        }
    }
    .teach-msg {
        margin-top: 0.12rem;
        padding-top: 0;
        flex-grow: 0;
    }
    .report-name {
        margin-top: 0.12rem;
        padding-top: 0;
        justify-content: unset;
        .report-name-list {
            width: 100%;
            padding-top: 0.08rem;
            .report-name-item {
                .flex;
                justify-content: space-between;
                border-bottom: 0.01rem solid #ededed;
                padding-bottom: 0.3rem;
                margin-top: 0.26rem;
                &:nth-child(1) {
                    margin-top: 0;
                }
                .content {
                    width: 100%;
                    .flex;
                    justify-content: space-between;
                    align-items: center;
                    .left {
                        .flex;
                        .img-box {
                            border-radius: 50%;
                            overflow: hidden;
                            margin-right: 0.15rem;
                            .img-wrap(0.82rem, 0.82rem);
                            > img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .name-date {
                            .name {
                                color: @black;
                                font-size: 0.28rem;
                                margin-bottom: 0.1rem;
                            }
                            .date {
                                font-size: 0.24rem;
                                color: @gray;
                            }
                        }
                    }
                    .state {
                        color: #1b6aad;
                        font-size: 0.28rem;
                    }
                }
            }

        }

    }

}

// 10 学校授课
.page-school-teaching {
    .panel-tab{
        background-color: #fff;
        .tab-list{
            >li{
                width: 50%;
                height: 0.88rem;
                margin-left: 0;
                a{
                    .flex-center;
                    width: 100%;
                    height: 100%;
                }
                &::after {
                    display: none;
                }
                &.active {
                    background-color: #1c9e3b;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .search-box{
        margin-left: 0;
        margin-right: 0;
    }
    .search-form{
        padding-top: .4rem;
        padding-bottom: .4rem;
        .addr-select-box{
            .area-select{
                padding-left: .2rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .react-select-addr-box{
                    width: 48%;
                    .react-select-addr{
                        width: 100%;
                    }
                }
            }
        }
    }
    .content-box{
        flex-grow: 1;
        .school-list {
            .flex;
            flex-wrap: wrap;
            .school-item {
                flex-grow: 1;
                border-radius: 0.08rem;
                a {
                    background-color: #fff;
                    display: block;
                    .title{
                        font-size: 0.28rem;
                        color: @black;
                        line-height: 1.5;
                    }
                    .icon-desc-wrapper{
                        margin-bottom: 0;
                        .icon-box{
                            color: @orange;
                        }
                        .desc{
                            font-size: 0.24rem;
                            color: @gray;
                        }
                    }
                }
            }
        }
    }
}

// 10-01授课列表
.course-index, .page-school-connect {
    .course-school {
        padding: 0.4rem 0.3rem;
        h3 {
            font-size: 0.28rem;
            color: @black;
        }
        .connect {
            margin-top: 0.2rem;
            &-item {
                margin-top: 0.1rem;
                .flex;
                // align-items: center;
                &:nth-child(1) {
                    margin-top: 0;
                }
                &:nth-child(2) {
                    align-items: center;
                }
                i {
                    font-size: 0.3rem;
                    color: @orange;
                }
                p {
                    margin-left: 0.1rem;
                    font-size: 0.24rem;
                    color: @gray;
                }
            }
        }
    }
    .select-list {
        margin-top: 0.31rem;
        .flex;
        flex-wrap: wrap;
        margin-bottom: 0.35rem;
        .select-div {
            flex-grow: 1;
            flex-shrink: 0;
            width: calc((100% - .6rem) / 2);
            box-sizing: border-box;
            overflow: hidden;
            background-color: #fff;
            padding: 0.08rem;
            border-radius: 0.04rem;
            &:nth-child(2n) {
                margin-left: 0.3rem;
            }
            &:nth-child(3), &:nth-child(4) {
                margin-top: 0.16rem;
            }
            .select-type {
                width: 100%;
                font-size: .26rem;
                background-color: transparent;
                color: @gray;
            }
        }
    }
    .form_submit_btn_box {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        .form_submit_btn {
            max-width: unset;
            flex-basis: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.26rem;
        }
    }
}

// 10-02详情
.course-detail {
    .course-detail-title {
        .report {
            width: 100%;
            padding: 0.53rem 0 0.32rem 0;
            .top {
                .flex;
                justify-content: space-between;
                align-items: center;
            }
            .msg {
                margin-top: 0.25rem;
                .item {
                    .flex;
                    margin-top: 0.1rem;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    .icon-box {
                        width: 0.25rem;
                        margin-right: .1rem;
                        .font_family {
                            color: @orange;
                            font-size: 0.25rem;

                        }
                    }
                    p {
                        .flex;
                        align-items: center;
                        color: @light-black;
                        font-size: 0.24rem;
                        line-height: 1.8;
                    }
                }
            }
        }
    }
    .phone {
        padding-bottom: 0.4rem;
        .name-phone-list {
            border-radius: 0.08rem;
            background-color: #f2f2f2;
            padding: 0.25rem;
            .name-phone-item {
                .flex;
                margin-top: 0.1rem;
                justify-content: space-between;
                font-size: 0.26rem;
                color: @light-black;
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
        }
    }
    .school-require {
        padding-bottom: 0.4rem;
    }
    .content {
        padding-bottom: 2.69rem;
    }
}
.course-address {
    margin-left: 3em;
    div {
        text-indent: -3em;
    }
}


// 点击协议查看
.agreement-popup-css {
    min-height: unset;
    // max-height: 5rem;
    .flex;
    flex-direction: column;
    align-items: center;
    .icon-close {
        right: 0;
        width: 0.66rem;
        height: 0.66rem;
    }
    .popup-header {
        padding: 0.6rem 0.5rem 0 0.5rem;
    }
    .popup-content {
        max-height: 5rem;
        flex: 1;
        width: 100%;
        overflow: auto;
        margin-top: 0;
        margin-bottom: 0.45rem;
        padding: 0 0.5rem;
    }
}

.date-tag {
    justify-content: unset !important;
    div {
        &:nth-child(1) {
            margin-right: 1.6rem !important;
        }
    }

}

.page-volunteer-consulting-service {
    #other-info {
        .form-group__top {
            .flex;
        }
    }
}

input[type="search"] {
    line-height: unset !important;
    .flex;
    align-items: center;
}


// 弹窗页面
.need-scroll-confirm {
    border-radius: 0.08rem;
    .flex;
    flex-direction: column;
    .popup-content {
        overflow-y: visible;
        padding: unset;
        &-text {
            margin-top: 0.32rem;
            overflow-y: auto;
            max-height: 5rem;
            .text {
                margin: 0 0.24rem;
            }
        }

    }
    .bottom {
        .flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.45rem;
        height: 0.8rem;

        .msg {
            opacity: 0;
            transition: all .3s;
            font-size: 0.3rem;
            color: @gray;
            .text-middle;
            line-height: 0.5rem;
        }
        .btn {
            opacity: 0;
            transition: all .3s;
            height: 0.8rem;
            border-radius: 1.08rem;
            width: 3.5rem;
            font-size: .28rem;
        }
        .msg,.btn{
            &.active{
                opacity: 1;
            }
        }
    }
}

.popup-mask {
    max-width: 414px;
}
.update-class-popup {
    .popup-header,
    .popup-content {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    .update-class-time {
        .layui-input-inline {
            display: flex;
            align-items: center;
            >div {
                flex-grow: 1;
                display: inline-block;
                font-size: 0.26rem;
                color: #333;
                text-align: center;
            }
            .layui-input {
                border-radius: 0.1rem;
                box-sizing: border-box;
                padding-left: 0.18rem;
                margin-left: 0.13rem;
                width: 3.7rem;
                height: 0.6rem;
                line-height: 0.6rem;
                font-size: 0.26rem;
                color: #999;
                border: solid 0.01rem #cccccc;
            }
            & + .layui-input-inline {
                margin-top: 0.55rem;
            }
        }
    
    }

}

.laydate-time-list {
    ol {
        overflow-y: auto !important;
    }
}


// 汇总页头部
.multi-agency-header {
    justify-content: center;
    box-shadow: 0 0.2rem 0.6rem 0 
    rgba(0, 0, 0, 0.1) !important;
}
.multi-agency-page {
    padding-bottom: 0.5rem;
    flex-grow: 1;
    background-color: #fff;
    .multi-agency-list {
        padding-top: 0.5rem;
        li {
            margin-top: 0.4rem;
            &:first-child {
                margin-top: 0;
            }
            a {
                padding-bottom: 0.44rem;
                border-bottom: 0.05rem solid #f5f5f5;
                display: flex;
                .img-box {
                    margin-right: 0.28rem;
                    flex-shrink: 0;
                    width: 2.11rem;
                    height: 1.73rem;
                    border: 0.01rem solid #d7d7d7;
                    border-radius: 0.08rem;
                    padding: 0.07rem;
                    .flex-center;
                    > img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .content {
                    width: calc(100% - 2.11rem - 0.28rem);
                    flex-grow: 1;
                    color: #999;
                    .title {
                        color: #333;
                        width: 100%;
                        font-size: 0.28rem;
                        margin-bottom: 0.1rem;
                    }
                    .people {
                        font-size: 0.26rem;
                        margin-bottom: 0.1rem;
                    }
                    .address {
                        line-height: 1.75;
                        font-size: 0.24rem;
                    }
                }
            }
        }
    }
}

// 多机构首页
.page-index-multi {
    .pic-module {
        ul {
            li {
                .img-box {
                    width: 2.1rem;
                    height: 1.47rem;
                    margin-right: 0.25rem;
                    .b-r;
                    overflow: hidden;
                }
                .content {
                    height: 1.47rem;
                    width: calc(100% - 2.1rem - 0.25rem);
                    font-size: 0.24rem;
                    color: #999;
                    h3 {
                        font-size: 0.28rem;
                        line-height: 1.5;
                        color: @black;
                    }
                }
                & + li {
                    margin-top: 0.35rem;
                }
            }
        }
    }
    // 公益活动
    .actities {
        .actities-list {
            .actities-item {
                .content {
                    h3 {
                        margin-bottom: 0.15rem;
                    }
                    .desc {
                        margin-bottom: 0.15rem;
                    }
                }
            }
        }
    }

    // 普法视频 
    .law-videos-item {
        .content {
            padding-bottom: 0.15rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}


// 参与指南
.page-multi-guide {
    ul {
        li {
            & + li {
                margin-top: 0.8rem;
            }
        }
    }
}

.multi-navigation {
    margin-top: 0.55rem;
    display: flex;
    justify-content: center;
    a {
        font-size: 0.24rem;
        color: #1c9e3b;
        padding-right: 0.2rem;
        .flex;
        align-items: center;
        & + a {
            padding-left: 0.2rem;
            border-left: 0.01rem solid #ccc;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}
.page-muti-register {
    > .container {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        .white-bg {
            padding: 0.3rem 0.25rem;
            background-color: #fff;
        }
        .buttons-main {
            > button {
                border-radius: 4px;
            }
        }
        .area-select-4 {
            flex-wrap: wrap;
            .react-select-addr-box {
                flex-basis: 49%;   
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 0.1rem;
                }
                .react-select-addr-box {
                    margin-left: 0;
                }
            }
            
        }
        .modal{
            overflow: unset;
            overflow-y: unset;
        }
        .modal-body{
                overflow-y: auto;
                max-height: 500px;
        }
    }
}

// 我的法律咨询，我的法律服务的tab栏
.panel-tab{
    background-color: #fff;
    .tab-list{
        >li{
            width: 50%;
            height: 0.88rem;
            margin-left: 0;
            a{
                .flex-center;
                width: 100%;
                height: 100%;
            }
            &::after {
                display: none;
            }
            &.active {
                background-color: #1c9e3b;
                a {
                    color: #fff;
                }
            }
        }
    }
}

.select-with-input {
    margin-top: 0.1rem;
}

.news-schools-container {
    background-color: #fff;
    margin-bottom: 1.06rem;
    padding: 0 0.3rem 0.5rem 0.3rem;
    margin-top: 0.3rem;
    .new-schools-list {
        .item {
            a {
                display: block;
                padding-top: 0.25rem;
                padding-bottom: 0.3rem;
                border-bottom: 0.02rem solid #ededed;
                .top {
                    .title {
                        font-size: 0.28rem;
                        color: #333;
                    }
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .left {
                        width: calc(80%);
                        flex-shrink: 0;
                        font-size: 0.24rem;
                        color: #999;
                        .school-name {
                            margin: 0.25rem 0 0.2rem 0;
                        }
                        .time {
                            margin-top: 0.2rem;
                            color: #ff6600;
                        }
                    }
                    .right {
                        flex-shrink: 0;
                    }
                }
            }
            & + .item {
                margin-top: 0.1rem;
            }
        }
    }
}

.offcanvas {
    .layui-laydate {
        position: fixed;
    }
}

// 用户反馈页面
.page-user-evaluate {
    padding-bottom: 2rem;
    .list_nav-container {
        padding: 0.3rem 0;
    }
    .user-evaluate-item {
        background-color: #fff;
        padding: 0.3rem 0.25rem;
        border-bottom: none;
        &:last-child {
            padding-bottom: 0.3rem;
        }
    }
}