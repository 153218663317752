@import "../../../commonStyle/var.less";
@import "../../../commonStyle/common.less";
@import "./var";

.bg(@src,@imgSuffix: png,@pos: center,@size: cover){
	background: url('@{image_url}@{src}.@{imgSuffix}') no-repeat @pos;
	background-size: @size;
}

main{
  padding-top: @header_height;
  &.no-header{
    padding-top: 0;
  }
}

@keyframes runLoading
{
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.bg-green-color {
	background-color: @green;
}

.bg-gray{
	background-color: @glay !important;
}

.page-bottom-space{
	padding-bottom: @page-bottom-space !important;
}

.b-r {
  border-radius: 0.08rem;
}
.b-r-s {
	border-radius: 0.03rem;
}
.row{
  height: 0.14rem;
  background-color: @bg-color;
  width: 100%;
  &.bar{
    margin-left: -@space_hor;
    margin-right: -@space_hor;
    width: auto;
  }
}

input[type=checkbox], input[type=radio]{
	width: .36rem;
	height: .36rem;
	flex-shrink: 0;
	appearance: none;
	padding: 1px;
}
input[type=checkbox]{
	.icon-checkbox__unchecked;
	&:checked{
		.icon-checkbox__checked;
	}
	&:disabled{
		.icon-checkbox__disabled;
	}
}

input[type=radio]{
	.icon-radio__unchecked;
	&:checked{
		.icon-radio__checked;
	}
	&:disabled{
		.icon-radio__disabled;
	}
}
select{
	appearance: none;
	padding-right: .4rem !important;
	.bg('icon/icon-triangle-green', png, 95% center, .188rem .122rem);
}
.select2{
	padding-right: .4rem !important;
}

.select2.select2-container .selection .select2-selection--single .select2-selection__arrow {
	height: 100%;
	top: 0;
	right: 0;
	b {
		top: 50%;
		left: 0;
		transform: translate(0, -0.128rem / 4);
		border-width: .128rem;
	}
}
.select2.green-select2.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: @green transparent transparent transparent;
}
.select2.green-select2.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent @green transparent;
	transform: translate(0, -(0.128rem / 4) * 3 );
}
select.green-select{
	flex-shrink: 0;
	color: @green;
	font-size: .24rem;
	height: .6rem;
	padding-right: .1rem;
	background-color: #fff;
	.bg('icon/icon-arrow-down-green', png, 95% center, .128rem .072rem);
}

.container{
  padding-left: @space_hor !important;
  padding-right: @space_hor !important;
}
.page-article-detail{
  .article-title{
    font-size: 28px;
    margin-bottom: 40px;
    color: @green;
    text-align: center;
  }
}
.setBg(@url,@w: 100%,@h: 100%,@position: left top) {
  background-image: url("@{image_url}@{url}");
  background-position: @position;
  background-size: 100% 100%;
  width: @w;
  height: @h;
  background-repeat: no-repeat;
}
.gird-l-r{
  .flex;
  .justify-between;
  > .l{
    flex-shrink: 0;
  }
  > .r{
    flex: 1;
    height: 100%;
  }
}
.grid-8-3{
  .flex;
  .justify-between;
  .col-8 {
    flex: 1;
    height: 100%;
    margin-right: 40px;
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px
      rgba(9, 9, 9, 0.12);
      &.mr0{
        margin-right: 0;
      }
  }
  .col-3{
    height: 100%;
    flex-shrink: 0;
    width: 328px;
    background-color: #ffffff;
	  box-shadow: 0px 2px 12px 0px
		rgba(9, 9, 9, 0.12);
  }
}

.list-item-hover{
  box-shadow: 0px 2px 12px 0px
  rgba(9, 9, 9, 0.12);
    .content{
      border-bottom: none;
      .title{
        color: @green;
      }
    }
}

.base-v-list {
  .flex;
  .flex-wrap;
  > .item {
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px
      rgba(9, 9, 9, 0.12);
    > .img,>.img-box {
      width: 100%;
      display: block;
    }
    .content{
      width: 100%;
      font-size: 0.28rem;
      color: #1a1a1a;
      padding: 0.23rem  0.2rem;
      text-align: left;
    }
  }
}

.base-table{
  .table-r{
    font-size: 16px;
    // color: @black;
    margin-bottom: 10px;
    > .item {
      .text-cut;
    }
    &.line{
      margin-bottom: 0;
      font-size: 14px;
    //   color: @light-black;
      padding: 16px 0;
    //   border-bottom: 1px solid @border-color;
    }
  }
}
.base-grid{
  background-color: #ffffff;
	box-shadow: 0px 2px .2rem 0px
    rgba(9, 9, 9, 0.24);
  width: 100%;
  padding: .4rem;
  &.form-grid{
    padding: .36rem .7rem;
    padding-bottom: .6rem;
  }
}
.no-more-data{
  width: 100%;
//   color: @gray;
  margin-top: .4rem;
  margin-bottom: .4rem;
  text-align: center;
}


.module-title{
  text-align: center;
  width: 5.6rem;
  margin: 0 auto;
  margin-top: 1.24rem;
  margin-bottom: .66rem;
  > h3{
    // color: @green;
    font-size: 0.36rem;
    position: relative;
    font-weight: bold;
    padding-bottom: 0.08rem;
  }
  .en-title{
    font-size: 0.24rem;
    // color: @gray;
  }
  &.sm-mb{
    margin-bottom: .5rem;
  }
}
.h-module-title{
  .flex;
  padding-left: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid @border-color;
  margin-bottom: 24px;
  align-items: flex-end;
  justify-content: space-between;
  .zh{
    position: relative;
    font-size: 0.36rem;
    color: #1a1a1a;
    margin-right: .14rem;
    &::before{
      position: absolute;
      left: -14px;
      top: 50%;
      width: 0.08rem;
      height: 0.36rem;
    //   background-color: @blue;
      transform: translateY(-50%);
      content: '';
      display: inline-block;
    }
  }
  .en{
    font-size: 0.3rem;
    // color: @gray;
  }
  .r{
    .flex;
    align-items: center;
    .tips-text{
      font-size: .24rem;
      color: #4d4d4d;
      margin-left: .1rem;
    }
  }
  &.sidebar{
    border-bottom: none;
    margin-bottom: 0;
  }
}
.v-module-title{
  .flex;
  .flex-direction;
  .align-center;
  .zh{
    margin-bottom: .08rem;
    font-size: 0.36rem;
    color: #1a1a1a;
  }
  .en{
    font-size: 0.24rem;
    // color: @gray;
  }
}
.mt20 {
  margin-top: 0.2rem;
}
.mt70{
  margin-top: .7rem;
}
.mt4{
  margin-top: 0.04rem;
}
.mt25{
  margin-top: .25rem;
}
.mt50{
  margin-top: .5rem;
}
.mt60{
  margin-top: .6rem;
}
.mr50{
  margin-right: .5rem;
}
.plr25 {
  padding-right: 0.25rem;
}
.no-data{
  margin-top: .6rem;
  margin-bottom: .6rem;
  text-align: center;
//   color: @gray;
}

.qs-popup-wrapper{
	padding: .5rem .3rem;
	background-color: #ffffff;
	border-radius: 0.08rem;
	&.flex-middle {
		.flex-center;
		flex-direction: column;
	}
	&.min-height {
		min-height: 5rem !important;
	}
	.icon-close{
		position: absolute;
		top: -1rem;
	    right: 0;
	    z-index: 9;
	}
	.popup-header{
		padding: 0;
	    text-align: center;
	    margin-bottom: .28rem;
	    > .en-title{
	      flex-direction: column;
	      justify-content: center;
	      font-size: .36rem;
	      color: #1a1a1a;
	      display: flex;
	      align-items: center;
	      > span{
	        font-size: .24rem;
	        // color: @gray;
	      }
	    }
    }

	.popup-content{
	    width: 100%;
	    max-height: 60vh;
		margin: 0;
		padding: 0 .2rem;
	}
	.popup-content-title{
		margin-bottom: 0.3rem;
		color: @black;
	}
	.popup-content-sub-title{
		font-size: .26rem;
		color: @gray;
		margin-bottom: .4rem;
	}
  .btn-group{
    .icon-close{
      position: static;
    }
  }
  &.w-660{
    width: 6.6rem;
  }
  &.no-padding{
    padding: 0;
  }
}
.en-title-wrap{
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    font-size: 20px;
    > .en{
      font-size: 14px;
      margin-left: 5px;
    }
  }
  .banner-top{
    width: 100%;
    height: 3rem;
    position: relative;
    .flex-center;
    .bg{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .center-wrap{
      position: relative;
      color: #1a1a1a;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 3;
      > .img{
        position: relative;
        z-index: 1;
        &.education-page-green{
          width: 0.46rem;
        }
        &.about-page-green{
          width: 0.46rem;
        }
        &.project-page-green{
          width: .41rem;
        }
        &.info-page-green{
          width: 0.36rem;
        }
        &.news-page-green{
          width: 0.46rem;
        }
      }
      .text{
        font-weight: bold;
        font-size: .42rem;
        margin-top: .06rem;
        margin-bottom: 0.04rem;
      }
      .en-text{
        font-size: 0.24rem;
        // color: @gray;
      }
    }
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.08);
      z-index: 2;
    }
  }
  img.brightness{
	filter: brightness(0.6);
  }



// 定义文字居中对齐
.text-middle {
	text-align: center;
}


// 定义空心按钮，边框为绿色
.hollow-btn {
	border: 0.01rem solid @green;
	background-color: @white;
	color: @green;
}

// 定义实心的按钮,背景颜色为绿色
.solid-btn {
	background-color: @green;
	color: @white;
}

// 背景
.page-space-top{
	padding-top: @page_space_top;
}
.page {
	background-color: @page-bg-color;
	padding-top: @page_space_top;
	padding-bottom: @page_space_top;
	padding-left: @space_hor;
	padding-right: @space_hor;
	flex-grow: 1;
	&.nopadding {
		padding: 0;
	}
}

// 加载更多
.dropload-down {
	flex-shrink: 0;
	margin-top: .5rem;
	font-size: 0.28rem;
	color: @green;
	text-align: center;
	line-height: 1.5;
	.dropload-refresh {
	}
	.loading{
		border-top-color: @green;
		border-left-color: @green;
		border-right-color: @green;
	}
}


// 首页的新闻动态、志愿培训、捐赠支持、个人中心的小图标
.nav-ico {
	width: 0.88rem;
	height: 0.88rem;
	.flex-center;
	border-radius: 0.1rem 0.44rem 0.44rem 0.44rem;
	background-color: #f5f5f5;
}

// 提交按钮
.submit-btn {
	width: 6rem;
	height: 0.88rem;
	border-radius: 0.44rem;
	font-size: 0.32rem;
	text-align: center;
	line-height: 0.88rem;
}

// 提交成功页面
.page-submit-success {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	background-color: @white;
	padding: 0rem 0.45rem;
	.flex;
	justify-content: center;
	align-items: center;
	.content {
		width: 100%;
		height: 100%;
		padding-top: 0.61rem;
		padding-left: 0.2rem;
		padding-right: 0.2rem;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		&.pt51 {
			padding-top: 0.51rem;
		}
		.title {
			width: 100%;
			.flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 0.4rem;
			> .img-box {
				width: 1.42rem;
				height: 1.16rem;
				.flex-center;
				margin-bottom: 0.45rem;
				> img {
					width: 100%;
				}
			}
			> h3 {
				font-size: 0.36rem;
				text-align: center;
				color: @black;
			}
		}
		pre {
			font-size: 0.28rem;
			text-align: center;
			color: #666;
			line-height: 1.7;
		}
		.ercode {
			margin-top: 0.52rem;
			.code {
				width: 3.46rem;
				height: 3.46rem;
				margin-bottom: .1rem;
				.flex-center;
				img {
					width: 100%;
					height: auto;
				}
			}
			span {
				display: block;
				.text-middle;
				font-size: 0.26rem;
				color: #999;
			}
		}
	}
}

// 两个按钮，第一个按钮bg为白色、第二个按钮bg为绿色
.btn-box {
	width: 100%;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		padding: 0 0.2rem;
		flex-basis: 40%;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 3.6rem;
		height: 0.78rem;
		font-size: 0.32rem;
		.btn.btn-radius;
		&:first-child{
			.btn.btn-green.btn-border;
		}
		&:last-child{
			.btn.btn-green;
		}
		& + .btn {
			margin-left: 0.5rem;
		}
	}
}



// 0.36rem的字体 颜色为333的字体
.f-s-36 {
	font-size: 0.36rem;
	color: #333;
}
.f-s-26 {
	font-size: 0.26rem;
	color: @light-black;
	line-height: 1.8;
}
.f-s-32 {
	font-size: 0.32rem;
	color: @black;
	line-height: 1.2;
}

// 单行文字输入框
.label-item {
	.label, label {
		font-size: 0.28rem;
		color: #333;
	}
	.input-box {
		width: 100%;
		height: 0.8rem;
		position: relative;
		display: flex;
		justify-content: space-between;
		.msg {
			flex-grow: 1;
			margin-top: 0.1rem;
			background-color: #f1f1f1;
			border-radius: 0.1rem;
			height: 100%;
			line-height: 0.8rem;
			font-size: 0.26rem;
			&.input {
				color: #999;
			}
			&.select{
				color: #666;
			}
			&.select + .select {
				margin-left: 0.21rem;
			}
		}

	}

}

// 历史记录
.history-tag {
	height: 0.6rem;
	background-color: @bg-glay;
	border-radius: 0.3rem;
	font-size: 0.26rem;
	color: @light-black;
	text-align: center;
	line-height: 0.6rem;
	&.active {
		background-color: @green;
		color: @white;
	}
}

.page-bg-gray {
  	background-color: @bg-color;
}
// 页面中的小模块
.page-module {
    background-color: @white;
    padding: 0.3rem 0.25rem;
	flex-grow: 1;
    .flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.08rem;
	& + &{
		margin-top: 0.2rem;
	}
    .title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.25rem;
	    transition: margin-bottom .3s;
		h3 {
			font-size: 0.36rem;
			line-height: 1.2;
			color: @black;
		  }
		.the-more {
			font-size: 0.28rem;
			color: @gray;
		}
	}
}

.page-white {
	background-color: #fff;
	padding: 0 0.3rem;
	&.mt {
		margin-top: 0.12rem;
	}
}

// 普通用户和志愿者用户共有的头部swiper
.page-index {
    width: 100%;
    background-image: url('@{image_url}index-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    // 头部轮播图
    .nav {
        .swiper-container {
            width: 6.6rem;
            height: 3.4rem;
            img {
                width: 100%;
                height: auto;
            }
            .swiper-pagination {
                right: 0.23rem;
                bottom: 0.14rem;
                left: unset;
                width: auto;
                .swiper-pagination-bullet {
                    opacity: unset;
                    background-color: @white;
                    border-radius: 0.03rem;
                    display: inline-block;
                    transition: @transition;
                    width: 0.12rem;
                    height: 0.05rem;
                    &-active {
                        width: 0.24rem;
                    }
                }
            }
        }
    }
	// 头部list
	.nav-list {
		margin-top: 0.21rem;
		background-color: @white;
		ul {
			width: 100%;
			height: 1.9rem;
			.flex;
			justify-content: space-around;
			align-items: center;
			li {
				height: 1.3rem;
				a {
					height: 100%;
					.flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
				}
				.icon {
					font-size: 0.43rem;
				}
				.text {
					font-size: 0.26rem;
					color: #4d4d4d;
					line-height: 1.2;
				}
			}
		}
	}
	// 免费法律志愿服务
    .free-law-serve, .volunteer-serve {
        .serve {
            .flex;
            justify-content: space-between;
            .common {
                flex: 1;
                height: 1.18rem;
                .flex-center;
                border-radius: 0.08rem;
                &:nth-child(1) {
                  color: @blue;
                }
                &:nth-child(2) {
                    color: @green;
                }
              &:nth-child(3) {
                  color: @orange;
              }
            }
        }
    }
}


.form-group{
	&__checkbox-with-title{
		.form-field__checkbox-with-title{
			.form-checkbox-with__desc{
				color: @green;
			}
		}
	}
	&__desc-tips{
		.field-name{
			.form-group__top-right{
				color: @green;
			}
		}
	}
}

.form-group__apart{
	margin-bottom: .4rem;
	padding-bottom: .7rem;
	border-bottom: 1px solid @border-color;
}

// 已认领 待认领 已完成 已过期
.tag{
	padding: 0 5px;
	height: 0.35rem;
	font-size: 0.24rem;
	text-align: center;
	flex-shrink: 0;
	border-radius: 0.06rem;
	.flex-center;
	line-height: 0.35rem;


	&.tag-green {
		background-color: @bg-green;
		color: @tag-green;
	}
	&.tag-orange {
		background-color: @bg-orange;
		color: @tag-orange;
	}
	&.tag-blue {
		background-color: @bg-blue;
		color: @tag-blue;
	}
	&.tag-gray {
		background-color: @bg-glay;
		color: @tag-glay;
	}
}
.bg-orange {
	background-color: @orange;
}

// 问题
.issues-list {
	.issues-item {
		width: 100%;
		a {
			width: 100%;
			display: flex;
			.icon {
				font-size: 0.38rem;
                flex-shrink: 0;
			}
			.left-content {
                width: calc(100% - .61rem);
				flex: 1;
				margin-left: 0.23rem;
				h3 {
					font-size: 0.28rem;
					color: @black;
					margin-bottom: 0.1rem;
					line-height: 1.5;
				}
				.tag-content {
					align-items: center;
					display: flex;
					.date, .kinds {
						font-size: 0.24rem;
						color: @gray;
					}
					div + div{
						margin-left: 0.67rem;
					}
					padding-bottom: 0.2rem;
					border-bottom: 0.01rem solid #ededed;
				}
			}

		}
		& + .issues-item {
			margin-top: 0.22rem;
		}
	}

}

.area-select-box{
	display: flex;
	align-items: center;
	flex-grow: 1;
	.area-select{
		flex-grow: 1;
		display: flex;
		align-items: center;
		margin-left: -3px;
		select{
			flex-grow: 1;
			margin-left: 3px;
		}
	}
}


.btn-group{
	display: flex;
	align-items: center;
	justify-content: center;
	.btn{
		height: .9rem;
		font-size: .32rem;
		flex-grow: 1;
		& + .btn{
			margin-left: .3rem;
		}
	}
}

.btn-from-group{
	margin-top: .8rem;
	padding-left: .3rem;
	padding-right: .3rem;
}

.upload-file-box{
	.ossuploader-add{
		display: inline-flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		border: 0;
		width: 100%;
		height: auto;
		i{
			display: none;
		}
		p{
			margin-top: 0;
			font-size: .28rem;
			color: @green;
			line-height: 1.2;
		}
	}
	.ossuploader-file-suffix{
		width: 40px;
		.text-cut;
	}
}

.auto-height{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.popup-wrapper{
	.popup-header{
		padding-top: .6rem;
		margin-bottom: .5rem;
		.title{
			text-align: center;
			font-size: .36rem;
			color: @black;
		}
	}
	.popup-content{
		// margin-bottom: 0.74rem;
		.btn-box{
			margin-top: .65rem;
		}
	}
}

.more-menu-box{
    cursor: pointer;
    padding-left: .2rem;
    padding-right: .2rem;
    margin-left: -.2rem;
    margin-right: -.2rem;
    height: .65rem;
    .flex-center;
    .icon-more-menu-wrapper{
	}
	&.active{
		.more-menu{
			opacity: 1;
			pointer-events: unset;
			transform: rotateY(0);
		}
	}
	.more-menu{
		position: absolute;
		top: 0;
		right: .7rem;
		opacity: 0;
		transition: all .3s;
		pointer-events: none;
		transform: rotateY(90deg);
		transform-origin: right center;
		.select-list{
			background-color: #fff;
			position: relative;
			z-index: 2;
			box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
			text-align: center;
			font-size: .26rem;
			display: flex;
			align-items: center;
			padding-top: .1rem;
			padding-bottom: .1rem;
			>li{
				padding-left: .25rem;
				padding-right: .25rem;
				flex-shrink: 0;
				height: .45rem;
				cursor: pointer;
				display: flex;
				align-items: center;
				&.orange{
					color: @orange;
				}
				& + li{
					border-left: 1px solid @border-color;
				}
				a {
					// font-family: SourceHanSansCN-Regular !important;
				}
			}
		}
	}
}


// 每个页面得白色背景
.page-bg-white {
	background-color: #fff;
	width: 100%;
	flex-grow: 1;
}

// 加载更多
.dropload-down {
	margin-top: 0.2rem;
	.dropload-refresh {
		color: @green;
		font-size: 0.28rem;
	}
}

.white-block-list{
	>li{
		border-radius: 4px;
		background-color: #fff;
		& + li{
			margin-top: .15rem;
		}
		>a{
			padding: .3rem .25rem;
		}
    }
}

.white-block-opera-bottom-list{
  .white-block-list;
  >li{
    padding: .3rem .25rem;
    >a{
      padding: 0;
    }
  }
  .opera-bottom{
    display: flex;
    align-items: flex-start;
    margin-top: .2rem;
    .text{
      font-size: .24rem;
      color: @black;
      margin-right: .2rem;
      flex-shrink: 0;
    }
    .opera-bottom__right{
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: -.1rem;
      margin-left: -.25rem;
      .btn{
        font-size: .26rem;
        height: .45rem;
        padding-left: .2rem;
        padding-right: .2rem;
        margin-bottom: .1rem;
        margin-left: .2rem;
      }
    }

  }
}


.more-menu-list{
	>li{
		.more-menu-box{
			position: absolute;
			right: .25rem;
			bottom: 0;
		}
	}
}


// 左边文字和小竖线，右边更多
.l-lineWord-r-more {
	margin-top: 0.44rem;
	margin-bottom: 0.25rem;
	.flex;
	justify-content: space-between;
	.line-word {
		border-left: 0.06rem solid @green;
		padding-left: 0.12rem;
		color: @black;
		font-size: 0.32rem;
	}
	.more {
		font-size: 0.28rem;
		color: @gray;
	}
}

.hundred-height {
	min-height: 100%;
	.flex;
	flex-direction: column;
}
.flex-one {
	.flex;
	flex: 1;
	flex-direction: column;
}

.fix-btn{
	position: fixed;
	right: .45rem;
	bottom: 2rem;
	box-shadow: 0 2px 12px 0 rgba(95, 95, 95, 0.14);
	.flex-center;
	color: @orange;
	background-color: #fff;


	border-radius: 50%;
	width: .9rem;
	height: .9rem;
	font-size: .24rem;
	padding: 0;
}
.pb-btn {
	padding-bottom: 2.3rem;
}

.page.pb-btn {
	padding-bottom: 2.3rem;
}
div.msgBox {
	width: 90% !important;
	left: 50% !important;
	margin-left: -45%;
	transform: translateY(-25%);
	padding: 1.3rem .3rem;
	.msgBoxTitle{
		span{
			display: inline-block;
			font-size: .3rem;
			border-bottom: 3px solid #68c258;
			margin-bottom: -2px;
			height: .55rem;
			line-height: .55rem;
		}
	}
	.msgBoxContent{
		margin-top: .3rem;
		width: auto;
		float: none;
		display: block;
		span{
			text-align: center;
		}
	}
	div.msgBoxButtons{
		margin-top: .8rem;
		display: flex;
		align-items: center;
		input[type='button'] {
			margin-left: .4rem;
			height: .8rem;
			line-height: .8rem;
			width: 1.8rem;
			color: #fff;
			border: 0;
			display: inline-block;
			flex-grow: 1;
			max-width: 3.61rem;
			font-size: .32rem;
			&:first-child{
				margin-left: 0;
				.btn.btn-green.btn-border;
				.btn.btn-radius;
			}
			&:last-child{
				.btn.btn-green;
				.btn.btn-radius;
			}
		}
	}
}

.summary-list {
	> li {
		.summary-list__title {
			font-size: 0.28rem;
		}

		.summary-list__desc {
			margin-top: .15rem;
			font-size: 0.36rem;
		}

		& + li {
		}
	}

	&.summary-list-reverse {
		> li {
			.summary-list__title {
				margin-top: .05rem;
			}

			.summary-list__desc {
				margin-top: 0;
			}
		}
	}
}

.search-form{
	padding: .4rem 0;
	@input-height: .6rem;
	&__row{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -.2rem;
		& + &{
			margin-top: .2rem;
		}
		&-item{
			margin-left: .2rem;
		}
	}
	input[type=text],input[type=number],select{
		height: @input-height;
		line-height: @input-height;
		color: @green;
	}
	.btn-search-with-icon{
		height: @input-height;
		width: .6rem;
		flex-shrink: 0;
		cursor: pointer;
		margin-left: .3rem;
		border: 1px solid @border-color;
		border-radius: 4px;
		.flex-center;
		background-color: #fff;
		.icon{
			color: @green;
		}
	}
}

.time-range-box{
	display: flex;
	align-items: center;
	.time-input-box{
		width: 2.2rem;
		position: relative;
		input[type=text]{
			width: 100%;
			padding-left: .6rem;
            vertical-align: middle;
		}
		.icon{
			color: @green;
			position: absolute;
			left: .15rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.line{
		width: .5rem;
		.flex-center;
		color: @gray;
		height: .6rem;
	}
}

.consult-detail{
	color: @gray;
	font-size: .24rem;
	display: flex;
	align-items: center;
	&__item{
		& + &{
			margin-left: .95rem;
		}
	}
}

.row-box{
	margin-left: -@space_hor;
	margin-right: -@space_hor;
}

.filter-tag-inner-box{
	background-color: #fff;
	padding-left: .25rem;
	padding-right: .25rem;
}
.filter-tag{
	display: flex;
	&.active{
		.filter-tag__list{
		}
		.filter-tag__fold{
			display: flex;
			.icon{
				transform: rotateZ(-90deg);
			}
		}
	}
	&.more-line{
		.filter-tag__fold{
			display: flex;
		}
	}
	&__list {
		overflow: hidden;
		transition: all .3s;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.15rem;
		margin-top: -0.25rem;
		>li{
			margin-top: 0.25rem;
			margin-left: 0.15rem;
			color: @light-black;
			font-size: 0.26rem;
			.flex-center;
			&.active {
				a{
					background-color: @green;
					color: @white;
				}
			}
			a{
				border-radius: 25px;
				background-color: #e6e6e6;
				padding: 0 .15rem;
				height: 0.6rem;
				.flex-center;
				min-width: 1.1rem;
			}
		}
	}
	&__fold{
		display: none;
		text-align: right;
		flex-shrink: 0;
		width: .7rem;
		padding-right: .1rem;
		&-icon{
			height: .6rem;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-grow: 1;
			.icon{
				transform: rotateZ(90deg);
				font-size: 0.24rem;
				color: @light-black;
				transition: all .3s;
			}
		}
	}
}

.popup-wrapper .icon-close{
	top: -.8rem;
}

.article-meta {
	background-color: #fff;
	padding: 0.65rem @space_hor 0.5rem @space_hor;
	.article-meta-title{
		color: @black;
		font-size: .36rem;
		line-height: 1.5;
	}
	.msg {
		margin-top: 0.34rem;
		.people-msg {
			.flex;
			align-items: center;
			margin-bottom: 0.37rem;
			.img-box {
				border-radius: 50%;
				overflow: hidden;
				.img-wrap(0.58rem, 0.58rem);
				> img {
					width: 100%;
					height: 100%;
				}
			}
			.name, span {
				font-size: 0.24rem;
			}
			.name {
				margin-right: 0.14rem;
				margin-left: 0.14rem;
				color: @light-black;
			}
			span {
				color: @gray;
			}

		}
		.tag-msg {
			color: @gray;
			font-size: 0.24rem;
			.flex;
			justify-content: space-between;
		}
	}
}

.article-box{
	padding: 0 0.3rem 2.3rem 0.3rem;
	background-color: #fff;
	flex-grow: 1;
}

pre[wrap]{
	font-size: .26rem;
	color: @light-black;
}

.icon-desc-wrapper{
	.icon-box{
	}
	.desc{
		color: @light-black;
		font-size: .24rem;
        line-height: 1.5;
        a{
          color: @orange;
        }
	}
}

.article-wrapper,.article-wrapper-box {
	position: relative;
	overflow: hidden;
	&__inline{
		&.active {
			.show-all {
				background: url('@{image_url}all.png') no-repeat center;
				background-size: cover;
				display: block;
				position: absolute;
				right: 0;
				z-index: 2;
				bottom: 0;
				cursor: pointer;
				text-align: center;
				line-height: normal;
				font-size: 0.26rem;
				padding-left: .2rem;
				color: @green;
			}
		}
	}
	.show-all {
		display: none;
	}

	pre{
		font-size: .26rem;
		line-height: 1.8;
	}
}

#alert-wrapper{
	.alert-wrapper-item{
		background-color: #9b9b9b !important;
		color: #fff;
		text-align: center;
		padding: .15rem .3rem !important;
		>img{
			display: none;
		}
		.alert-msg{
			margin-left: 0;
			font-size: .36rem;
			line-height: 1.5;
			padding: 0;
		}
		.time-line-wrap{
			left: 0;
			bottom: .1rem;
			background-color: #9b9b9b !important;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.loading-box{
	position: relative;
	.loading-wrapper{
		pointer-events: none;
		position: absolute;
		z-index: 3;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background-color: #fff;
		transition: all .3s;
		.loading{
			position: absolute;
			top: 100px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
		}
	}
	&__active{
		.loading-wrapper{
			opacity: 1;
		}
	}
}
.loading{
	width: 150px;
	height: 15px;
	margin: 0 auto;
	margin-top:100px;
	span{
		display: inline-block;
		width: 15px;
		height: 100%;
		margin-right: 5px;
		background: #90ee90;
		transform-origin: right bottom;
		animation: load 1s ease infinite;
		&:last-child{
			margin-right: 0;
		}
		&:nth-child(1){
			animation-delay: .13s;
		}
		&:nth-child(2){
			animation-delay: .26s
		}
		&:nth-child(3){
			animation-delay: .39s;
		}
		&:nth-child(4){
			animation-delay: .52s;
		}
		&:nth-child(5){
			animation-delay: .65s;
		}
	}
}

@-webkit-keyframes load{
	0%{
		opacity: 1;
		transform: scale(1);
	}
	100%{
		opacity: 0;
		transform: rotate(90deg) scale(.3);
	}
}

//posfixed  start
.posfixed-clone{
  position: fixed;
  left: 15px !important;
  right: 15px;
  top: 0;
  transition: all .3s;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 3;
  margin-top: 0 !important;
  transform: translateY(-110%);
  &.active{
    transform: translateY(0);
  }
}
//posfixed  end

.fix-top-menu-list,.tab-list{
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all .3s;
  font-size: .3rem;
  &.to-top{
    transform: translateY(-100%);
  }
  &.tab-justify-start{
    justify-content: start;
    >li{
      padding: 0 .2rem;
    }
  }
  >li{
    flex-shrink: 0;
    position: relative;
    font-size: .26rem;
    height: 100%;
    cursor: pointer;
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -.15rem;
      height: .06rem;
      width: .3rem;
      bottom: .1rem;
      background-color: @green;
      transition: all .3s;
      transition-delay: .1s;
      transform-origin: center;
      transform: scaleX(0);
      border-radius: 4px;
    }
    &.active{
      &:after{
        transform: scaleX(1);
      }
      a{
        color: @black;
      }
    }
    a{
      height: 100%;
      display: flex;
      align-items: center;
      color: @light-black;
    }
    & + li{
      margin-left: .5rem;
    }
  }
}
.tab-list{
  &.with-border-bottom{
    border-bottom: 1px solid @border;
    margin-bottom: .3rem;
  }
  &.align-left{
    justify-content: flex-start;
    >li{
      & + li{
        margin-left: 1.2rem;
      }
    }
  }
}


.fix-top-menu-list{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  box-shadow: 0 -2px 16px 0 rgba(108, 83, 83, 0.4);
  .container;
  height: .8rem;
}

.tab-list{
  height: .85rem;
  &__left{
    justify-content: flex-start;
    >li{
      & + li{
        margin-left: 1.05rem;
      }
    }
  }
}

.school-course-list {
  .course-list-li {
    margin-top: 0.23rem;
    &:nth-child(1) {
      margin-top: 0;
    }
    .course-item {
      .flex;
      .left {
        .flex;
        flex-direction: column;
        width: 0.85rem;
        .year {
          font-size: 0.28rem;
          text-align: center;
          background-color: #fff;
          color: @light-black;
          padding: 0 0.1rem;
          border: solid 0.01rem #dbdbdb;
        }
        .mouth {
          font-size: 0.24rem;
          text-align: center;
          background-color: #dbdbdb;
          color: @light-black;
          padding: 0 0.1rem;
        }
      }
      .right {
        margin-left: 0.2rem;
        padding-bottom: 0.2rem;
        width: calc(100% - 0.85rem - 0.2rem);
        border-bottom: 0.02rem solid #ededed;
        h3 {
          font-size: 0.28rem;
          color: @black;
        }
        .details {
          .flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.1rem;
          .msg {
            .flex;
            .time {
              font-size: 0.24rem;
              color: @orange;
              margin-right: 0.8rem;
            }
            .classname {
              font-size: 0.24rem;
              color: @light-black;
            }
          }
        }
      }
    }
  }

}

.component-select-2 {
	position: relative;
	.lenovoBar-btn{
		margin-left: auto;
		padding: .08rem .2rem;
		text-align: center;
		background: @green;
		font-size: .28rem;
		border-radius: 0.08rem;
		color: #fff;
		cursor: pointer;
	}
	.search-bar.form-field.form-field_text {
		position: relative;
		> input {
			padding-right: 0.4rem;
		}
		> .search-icon {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			right: 0.1rem;
			width: 0.3rem;
			height: 0.3rem;
			overflow: hidden;
			border-radius: 50%;
			.iconfont {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
	.lenovoBar{
		position: absolute;
		background: #fff;
		width: 100%;
		max-height: 5rem;
		overflow-y: auto;
		z-index: 111;
		border: 1px solid #ccc;
		padding: .2rem .3rem;
		&.show{
			display: block;
		}
		.lenovoBar-item{
			padding: .1rem 0;
			display: flex;
			align-items: center;
			.text{
				font-size: .28rem;
				color: #000;
			}
		}  
		.nodata{
			padding: .1rem 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.tips{
				font-size: .28rem;
				color: #000;
			}

		}
	}
}
