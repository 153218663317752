@import "var";

.page{
  .icon-desc-wrapper{
    &:first-of-type{
      margin-bottom: .35rem;
      flex-shrink: 0;
    }
    & + .page-module{
      margin-top: 0;
    }
  }
}

.page-index{
  .nav{
    padding-top: .2rem;
  }
}


.page-law-requirement-server {
  .apply-module{
    .apply-desc,.solemnity-desc{
      font-size: .26rem;
      margin-bottom: .35rem;
    }
    .apply-desc{
      color: @light-black;
    }
    .solemnity-desc{
      color: @black;
      font-weight: bold;
    }
  }
}

.page-volunteer-apply-service{
  .desc{
    font-size: .26rem;
    color: @black;
    margin-bottom: .35rem;
  }
}

.page-news-add{
  .guide-tips{
    cursor: pointer;
    color: @blue;
  }
}

.page-user-index{
  padding-top: 0;
  .user-panel{
    .bg(bg-user-index, jpg);
    margin-left: -@space_hor;
    margin-right: -@space_hor;
    margin-bottom: -.7rem;
    height: 2.8rem;
    .user-panel-content{
      .flex-center;
      height: 2.1rem;
      .avatar{
        padding: 2px;
        background-color: #fff;
        .avatar(1.2rem);
        margin-right: .2rem;
        .flex-center;
      }
      .content{
        .user-name{
          font-size: .32rem;
          color: #fff;
          .text-cut;
        }
        .info{
          margin-top: .15rem;
          font-size: .24rem;
          display: flex;
          align-items: center;
          .level{
            background-color: @bg-orange;
            color: @orange;
            margin-right: .25rem;
            height: .34rem;
            padding: 0 .1rem;
            border-radius: 25px;
          }
          .service-time{
            color: #fff;
          }
        }
      }
    }
  }
  .user-news{
    margin-bottom: .2rem;
    padding: .2rem .3rem;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .left{
      flex-grow: 1;
      margin-right: .7rem;
      .title{
        font-size: .3rem;
        color: @black;
        margin-bottom: .1rem;
      }
      .content{
        font-size: .24rem;
        color: @gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .school{
        }
        .time{

        }
      }
    }
    .icon{
      color: @light-black;
    }
  }
  .user-menu-module{
    padding-top: .2rem;
    .user-menu-list{
      padding-bottom: .3rem;
      >li{
        a{
          display: flex;
          align-items: center;
          height: 1rem;
          .icon-box{
            width: .5rem;
            flex-shrink: 0;
            font-size: .32rem;
          }
          .content{
            border-bottom: 1px solid @border-color;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
            .text{
              font-size: .32rem;
              color: @black;
            }
            .icon{
              color: @light-black;
            }
          }
        }
      }
    }
  }
}

.page-user-profile{
  .btn-group{
    .btn{
      width: 3.6rem;
      height: .8rem;
      flex-grow: 0;
    }
  }
}

.page-user-train-list{
  .icon-desc-wrapper{
    &:first-of-type{
      margin-bottom: .3rem;
      color: @light-black;
    }
  }
  .user-train-list{
    .white-block-list;
    .more-menu-list;
    .list(2.1rem, 1.47rem, .25rem, .2rem);
    >li{
      position: relative;
      a{
        .img{
          border-radius: 4px;
        }
        .content{
          position: relative;
          .title{
            font-size: .28rem;
            color: @black;
            .text-cut;
            margin-bottom: .15rem;
          }
          .info{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-size: .24rem;
            .content-left{
              margin-right: .3rem;
              color: @gray;
              .content-left__item{
                & + .content-left__item{
                  margin-top: .15rem;
                }
              }
            }
            .content-right{

            }
          }
        }
      }
    }
  }
}

.volunteer-teaching-list{
  .white-block-opera-bottom-list;
  >li{
    position: relative;
    >a{
      display: block;
      .top{
        margin-bottom: .15rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
          font-size: .28rem;
          color: @black;
        }
        .tag{

        }
      }
      .content{
        .content-item{
          margin-bottom: 0;
          .icon{
            color: @orange;
          }
          .desc{
            font-size: .24rem;
            color: @gray;
          }
          & + .content-item{
            margin-top: .05rem;
          }
        }
      }
    }
    >.btn{
      display: flex;
      position: absolute;
      right: .25rem;
      bottom: .25rem;
      padding: 0;
      font-size: .26rem;
      width: 1.45rem;
      height: .55rem;
      line-height: .55rem
    }
  }
}

.user-legal-advice-list{
  .white-block-list;
  .more-menu-list;
  >li{
    position: relative;
    a{
      display: block;
      position: relative;
      .top{
        margin-bottom: .25rem;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .title{
          font-size: .28rem;
          color: @black;
          .text-cut-2;
          margin-right: .6rem;
        }
      }
      .content{
        display: flex;
        align-items: center;
        color: @gray;
        font-size: .24rem;
        .time{
          margin-right: 1rem;
        }
        .type{
          max-width: 2rem;
          .text-cut;
        }
      }
    }
  }
}

.page-volunteer-train-list{

}

.page-consulting-service{
  .page-module{
    flex-grow: 0;
    .content{

    }
  }
  .btn{
    margin-top: .8rem;
    height: .8rem;
  }
}

.legal-advice-list{
  .white-block-list;
  .more-menu-list;
  >li{
    position: relative;
    >a{
      display: block;
      .top{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: .25rem;
        .title{
          flex: 1;
          margin-right: .6rem;
          font-size: .28rem;
          color: @black;
          line-height: 1.5;
          .text-cut-2;
        }
      }
      .content{
        .consult-detail();
      }
    }
  }
}

.page-volunteer-consulting-service{
  .page-module{
    flex-grow: 0;
  }
  .service-info{
    .content{
      font-size: .28rem;
      color: @black;
      line-height: 1.5;
    }
  }
  .btn{
    margin-top: .8rem;
    height: .8rem;
  }
}

.page-module-fold{
  .title{
    cursor: pointer;
    .icon-box{
      width: .5rem;
      height: .5rem;
      .flex-center;
      transition: all .3s;
      transform: rotateZ(90deg);
    }
    &.active{
      .icon-box{
        transform: rotateZ(-90deg);
      }
    }
  }
  .fold-area{
    transition: all .3s;
    overflow: hidden;
    height: 0;
    &.active{
    }
    &.shrink{
      height: 0;
    }
    &.grow{
      height: auto;
    }
  }
}

.page-volunteer-service-record{
  padding: 0;
  .summary-box{
    height: 1.8rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    .summary-list{
      display: flex;
      align-items: center;
      width: 66%;
      >li{
        width: 50%;
        &:nth-child(2){
          border-left: 1px solid @border-color;
          border-right: 1px solid @border-color;
          text-align: center;
        }
      }
    }
    .download-btn-box{
      width: 33%;
      text-align: right;
      padding-right: .3rem;
      .btn{
        width: 1.3rem;
        height: .48rem;
        font-size: .24rem;
        padding: 0;
      }
    }
  }
}

.page-volunteer-service-record{
  .volunteer-service-record-list{
    .white-block-list;
    .more-menu-list;
    >li{
      >a{
        display: flex;
        align-items: center;
        .left{
          margin-right: .5rem;
          flex-grow: 1;
          .title{
            font-size: 0.28rem;
            color: @black;
            line-height: 1.5;
            margin-bottom: 0.25rem;
            .text-cut-2;
          }
        }
        .data-stat{
          flex-shrink: 0;
          font-size: .24rem;
          color: @orange;
          .data-stat__item{
            display: flex;
            align-items: center;
            .symbol{
              font-size: .36rem;
              margin-right: 5px;
            }
            & + .data-stat__item{
              margin-bottom: .1rem;
            }
          }
        }
      }
    }
  }
}

.page-cert-apply{
  .info{
    flex-grow: 0;
    padding-bottom: 0;
  }
  .page-module{
    justify-content: flex-start;
    .btn-box{
      margin-top: .9rem;
    }
  }
  .search-form{
    padding-top: 0;
    .search-form__row{
      margin-bottom: .3rem;
      .time-input-box{
        input{
          background-color: @input-bg-color;
        }
      }
      .btn-search-with-icon{
        background-color: @input-bg-color;
      }
    }
  }
}

.page-cert-apply-detail{
  .service-content{
    font-size: .28rem;
    line-height: 1.5;
  }
  .form-group{
    .form-group__top{
      .field-name{
        width: 2.2rem;
      }
    }
  }
}

.page-news-index{
  .filter-tag{
    padding-top: .4rem;
    padding-bottom: .4rem;
  }
  .news-list{
    .list(2.1rem, 1.45rem, .25rem);
    >li{
      & + li{
        margin-top: .35rem;
      }
      a{
        .img{
          border-radius: 4px;
        }
        .content{
          .news-title{
            margin-bottom: .15rem;
            font-size: .28rem;
            color: @black;
            line-height: 1.5;
            .text-cut-2;
          }
        }
      }
    }
  }
}

.page-teaching-detail{
  .teach-meta{
    padding-top: .55rem;
    padding-bottom: .5rem;
    .title-box{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: .1rem;
      .title{
        color: @black;
        font-size: 0.36rem;
        line-height: 1.5;
      }
      .tag{
        margin-left: .3rem;
      }
    }
    .icon-desc-wrapper{
      .icon-box{
        color: @orange;
        i{
          font-size: .3rem;
        }
      }
    }
  }
  .connect-info-box{
    padding-bottom: .4rem;
    .connect-info-list{
      background-color: @bg-color;
      font-size: .26rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: @light-black;
      padding: .15rem @space_hor .25rem;
      li{
        margin-top: .1rem;
        width: 50%;
        &:nth-child(even){
          text-align: right;
        }
      }
    }
  }
}

.page-add-consulting{
  form{
    padding-bottom: @page-bottom-space;
  }
}

.comment-type-list{
  >li{
    border-bottom: 1px solid @border-color;
    a{
      padding: .4rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        .title{
          color: @black;
          font-size: .28rem;
        }
        .date{
          color: @gray;
          margin-top: .15rem;
          font-size: .24rem;
        }
      }
      .btn{
        width: 1.4rem;
        height: .55rem;
        font-size: .26rem;
      }
    }
  }
}

.page-user-comment-type-list{
  background-color: #fff;
}

.page-user-comment-detail{
  .comment-type-list{
    >li{
      a{
        padding-top: 0;
        padding-bottom: .35rem;
        .btn{
          width: 1.8rem;
          font-size: .24rem;
          padding: 0;
        }
      }
    }
  }
}

.comment-box{
  .form-group{
    margin-top: .4rem;
    .field-name{
      font-size: .28rem;
      margin-bottom: .2rem;
    }
    .form-field{
      color: @light-black;
      font-size: .26rem;
      padding-left: 0;
      &:before{
        content: '答:';
        display: inline-block;
        color: @green;
        margin-right: 5px;
      }
      >div{
        display: inline;
      }
    }
  }
}

.follow-official-account-popup{
  .img-box{
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
  .desc{
    text-align: center;
    margin-top: .2rem;
    font-size: .26rem;
    color: @light-black;
    line-height: 1.5;
  }
}

.form-msg{
  margin-top: 10px !important;
}