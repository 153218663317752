@import "./var";

.btn{
	text-align: center;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	font-size: 16px;
	cursor: pointer;
	background-color: @green;
	color: #fff;
	padding: 0 20px;

	.btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
		background: @bg-color;
		color: #fff;
		border: 1px solid @border-color;
		&.btn-border{
			color: @color;
			background: #fff;
			border: 1px solid @color;
			// &:hover{
			// 	background-color: @border-color;
			// 	color: #fff;
			// }
		}
	}

	&.btn-blue{
		.btn-color(@blue);
	}

	&.btn-orange{
		.btn-color(@orange);
	}

	&.btn-red{
		.btn-color(@red);
	}

	&.btn-green{
		.btn-color(@green);
	}


	&.btn-white{
		.btn-color(#fff);
	}

	&.btn-transparent{
		.btn-color(transparent, #fff, #fff);
	}

	&.btn-gray{
		.btn-color(rgb(177, 177, 177));
	}

	&.btn-square{
		border-radius: 0 !important;
	}

	&.btn-radius{
		border-radius: 25px !important;
	}

	&.btn-square-radius{
		border-radius: 4px !important;
	}

	&.btn-block{
		display: flex;
		width: 100%;
	}

	&[disabled]{
		opacity: .6;
		pointer-events: none;
	}



}

.text-orange{
	color: @orange !important;
}

.as-a{
	cursor: pointer;
}
.text-pre-wrap{
  white-space: pre-wrap;
}
.text-right{
	text-align: right;
}
.text-center{
	text-align: center;
}
.text-cut {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-cut-3 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.text-cut-4 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.flex {
	display: flex;
}
.flex-shrink{
	flex-shrink: 0;
}
.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: flex-center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}
.flex-center{
  .flex;
  .align-center;
  .justify-center;
}


.l-img-r-content{
  .flex;
  .justify-between;
  align-items: center;
  > .img,>.img-box{
    flex-shrink: 0;
	.flex-center;
	> img {
		width: 100%;
		height: auto;
	}
  }
  .content{
    flex: 1;
  }
}
.hidden {
    display: none !important;
}
.radius-bg{
	background-color: #f5f6f7;
}

.en{
	word-break: break-word;
}
.tab-pane{
	display: none;
	&.active{
		display: block;
	}
}

.bg-white{
	background-color: #fff !important;
}
.popup-wrapper,.msgBox{
	.msgBoxContent,.article-content{
		p,div,span{
			word-break: break-word;
			text-align: justify;
			width: 100%;
		}
	}
}
.banner-bg.error{
	&::before{
	  content: "";
	  position: absolute;
	  display: block;
	  background-color: #f7f7f7;
	  left: 0%;
	  top: 0%;
	  width: 100%;
	  height: 100%;
	}
	&::after{
		opacity: 0;
	}
  }
  .hover-boxShadow-green{
    &:hover{
		box-shadow: 0 0 10px 0 @green;
	}
  }
  .hover-boxShadow-light-green{
	&:hover{
		box-shadow: 0 0 10px 0 @light-green;
	}
  }
  .hover-boxShadow-brown{
	&:hover{
		box-shadow: 0 0 10px 0 @brown;
	}
  }


.red-point{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		right: -5px;
		top: 0;
		background-color: @orange;
		width: .1rem;
		height: .1rem;
		border-radius: 50%;
	}
}

div.msgBoxBackGround {
	top: 0;
	left: 0;
	position: fixed;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 0.6;
	z-index: 1000;
}
div.msgBox {
	box-sizing: border-box;
	padding: 15px;
	position: fixed;
	z-index: 1050;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
	.msgBoxTitle {
		width: 100%;
		border-bottom: 0;
		color: #333;
		font-size: 18px;
		text-align: center;
		font-weight: normal;
	}
	.msgBoxImage {
		display: none;
	}
	.msgBoxContent {
		color: #333;
		font-size: 14px;
		line-height: 1.6;
		span{
			color: #333;
		}
	}

	div.msgBoxButtons {
		display: flex;
		justify-content: space-around;
	}
}

.summary-list {
	> li {
		.summary-list__title {
			color: @light-black;
		}

		.summary-list__desc {
			color: @orange;
		}

		& + li {
		}
	}

	&.summary-list-reverse {
		> li {
			display: flex;
			flex-direction: column-reverse;
			.summary-list__title {
			}

			.summary-list__desc {
			}
		}
	}
}

.title-desc-item{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: .25rem;
	&__title{
		font-size: .28rem;
		color: @black;
	}
	&__desc{
		font-size: .26rem;
		color: @light-black;
		&.big-font{
			font-size: .36rem;
		}
	}
}

.icon-desc-wrapper{
	display: flex;
    align-items: flex-start;
	& + &{
		margin-top: .1rem;
	}
	.icon-box{
		flex-shrink: 0;
		width: .45rem;
		display: flex;
		align-items: flex-start;
		.icon{
			color: @orange;
		}
	}
}

.form-group{
	.area-select{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: -3px;
		>select{
			flex-shrink: 1;
			flex-grow: 1;
			margin-left: 3px;
		}
	}
	.addr-select-detail{
		margin-top: 5px;
	}
}

.area-select-3{
	.react-select-addr-box{
		width: 32%;
	}
}

