@import './var';
@import '../../../commonStyle/var.less';



.mt30{
  margin-top: .3rem !important;
}

.mb30{
  margin-bottom: .3rem !important;
}

.number-left-list(@mr, @number, @mt:30px) {
  margin-top: -@mt;
  width: 100%;
  .flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
      margin-top: @mt;
      width: calc((100% - @mr * (@number - 1)) / @number);
      margin-right: @mr;
      &:nth-child(@{number}n) {
          margin-right: 0;
      }
  }
}