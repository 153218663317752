@import "../../../commonStyle/reset.less";
@import "../../../commonStyle/tool.less";
@import "../../../commonStyle/common.less";
@import "./var";
@import "./tool";
@import "./common";
@import "./icon";
@import "./xh.less";
@import "./wj.less";

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.has-fix-bottom{
  margin-bottom: 1.4rem;
}
html, body{
  max-width: @max_width;
  margin: 0 auto;
}
html{
  border-right-width: 0 !important;
}
html body{
  background-color: #f0f2f5;
}
.header {
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(3, 14, 23, 0.1);
  height: @header_height;
  max-width: @max_width;
  > .container {
    width: 100%;
  }
  .r{
    .flex;
    .align-center;
    .to-search-page{
      .iconfont{
        color: @green;
        font-size: .5rem;
      }
    }
    .switch-button {
      display: flex;
      align-items: center;
      width: 1rem;
      height: 1rem;
      justify-content: flex-end;
      position: relative;
      &:after{
        position: absolute;
        content: '';
        display: inline-block;
        width: .3rem;
        height: 100%;
        right: -.3rem;
        background-color: transparent;
        top: 0;
      }
    }
  }
  .logo-box {
    > img{
      height: .64rem;
      width: auto;
    }
  }
}
.sidebar-menu {
  display: none;
  position: fixed;
  font-size: 0.28rem;
  background-color: rgba(0,0,0,0.5);
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
  opacity: 0;
  .mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: all 0.5s;
    background-color: #fff;
    width: 74%;
    height: 100vh;
    .user-wrap{
      padding-right: .38rem;
      padding-top: 1.7rem;
      color: #fff;
      font-size: 0.3rem;
      a {
        color: #fff;
        > .nickname{
          max-width: 1.56rem;
          margin-left: .16rem;
          margin-bottom: 0;
          .text-cut;
        }
      }
    }
    .nav-list-box {
      padding-top: 0.67rem;
      font-size: 0.36rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      > .menu-item {
        > a {
          .flex;
          .align-center;
          .justify-between;
          color: @black;
          padding: 0.3rem 0;
          padding-left: 0.32rem;
          padding-right: 0.3rem;
          position: relative;
          width: 100%;
          box-sizing: border-box;
          .l{
            .flex;
            .align-center;
            .menu-child-icon{
              margin-right: .26rem;
            }
          }
        }
        &.active {
          > a{
            color: @brown;
          }
        }
        & > .sub-menu {
          display: none;
          margin-top: .34rem;
          > .menu-item {
            display: flex;
            align-items: center;
            position: relative;
            font-size: 0.3rem;
            padding-bottom: .28rem;
            &:last-child{
              padding-bottom: 0;
            }
            &:hover,&.active {
              > a {
                color: @green;
                &:before {
                  content: '';
                  display: inline-block;

                  position: absolute;
                  left: 0.6rem;
                  top: 0;
                  width: 0.18rem;
                  height: 0.18rem;
                }
              }
            }
            > a {
              color: @gray;
              font-size: 0.26rem;
              width: 100%;
              margin-left: 1rem;
              display: inline-block;
              position: relative;
            }
            &.current-menu-item {
              a {
                color: @green;
                &:before {
                  content: '';
                  display: inline-block;

                  position: absolute;
                  background-size: 100% 100%;
                  background-position: left top;
                  left: 0.6rem;
                  top: 0;
                  width: 0.18rem;
                  height: 0.18rem;
                }
              }
            }
          }
        }
        &:nth-child(1){
          .menu-child-icon{
            width: 0.44rem;
          }
        }
        &:nth-child(2),&:nth-child(4){
          .menu-child-icon{
            width: 0.46rem;
          }
        }
        &:nth-child(4){
          .menu-child-icon{
            margin-right: .2rem;
          }
        }
        &:nth-child(3){
          .menu-child-icon{
            width: 0.41rem;
          }
        }
        &:nth-child(5){
          .menu-child-icon{
            width: 0.36rem;
          }
        }
      }
    }
  }
}
