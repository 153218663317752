.text-cut-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-start{
    justify-content: flex-start !important;
}

//图片超出裁剪
.img-wrap(@width,@height: auto) {
    width: @width;
    height: @height;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    flex-shrink: 0;
}

//设置圆形图片
.avatar(@size) {
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: @size;
    height: @size;
    line-height: @size;
    font-size: 0;
    flex-shrink: 0;
    img {
        border-radius: 50%;
    }
}

.list(@img_width, @img_height, @mr_width, @mb_width: 0){
    margin-bottom: -@mb_width;
    >li{
        display: flex;
        align-items: center;
        margin-bottom: @mb_width;
        >a{
            display: flex;
            width: 100%;
        }
        .img{
            .img-wrap(@img_width, @img_height);
            margin-right: @mr_width;
        }
        .content{
            width: calc(100% - @img_width - @mr_width);
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }
}



pre[wrap]{
    word-break: break-all;
}

.mb0{
    margin-bottom: 0 !important;
}

.pt0{
    padding-top: 0 !important;
}

